import React from "react";
import styles from "../../styles/styles";
import CountDown from "./CountDown";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addTocart } from "../../redux/actions/cart";
import { toast } from "react-toastify";
import { AiOutlineWhatsApp } from "react-icons/ai";

const EventCard = ({ active, data }) => {
  const { cart } = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const addToCartHandler = (data) => {
    const isItemExists = cart && cart.find((i) => i._id === data._id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (data.stock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, qty: 1 };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };
  return (
    <div
      className={`w-full block bg-white rounded-lg ${
        active ? "unset" : "mb-12"
      } lg:flex p-2`}
    >
      <div
        className=" relative flex top-[-1px] left-[-4px]"
        style={{ alignItems: "flex-start" }}
      >
        {data.originalPrice && (
          <span className="text-[11px] md:text-xs w-[70px] h-[25px] font-bold text-white text-brand-light uppercase inline-block bg-[#dd1717] px-2.5 pt-1 pb-[3px] mx-0.5 sm:mx-1 rounded-tl-md rounded-br-md">
            {Math.round(
              ((data.originalPrice - data.discountPrice) / data.originalPrice) *
                100
            )}
            % OFF
          </span>
        )}
      </div>
      <div className="w-full lg:-w[45%] m-1">
        <img src={`${data.images[0]?.url}`} alt="" />
      </div>
      <div className="w-full lg:[w-50%] flex flex-col justify-center">
        <h2 className={`${styles.productTitle}`}>{data.name}</h2>
        <p>{data.description}</p>
        <div className="flex py-2 justify-between">
          <div className="flex">
            <h5 className="font-[500] text-[18px] text-[#d55b45] pr-3 line-through">
              ₹ {data.originalPrice}
            </h5>
            <h5 className="font-bold text-[20px] text-[#333] font-Roboto">
              ₹ {data.discountPrice}
            </h5>
          </div>
          {/* <span className="pr-3 font-[400] text-[17px] text-[#44a55e]">
            {data.sold_out} sold
          </span> */}
        </div>
        <CountDown data={data} />
        <br />
        <div className="flex items-center">
          <Link to={`/product/${data._id}?isEvent=true`}>
            <div className={`${styles.button} text-[#fff]`}>See Details</div>
          </Link>

          <div className={`${styles.button} text-[#fff] ml-5`}>
            <a
              href="https://api.whatsapp.com/send?phone=9470814991&text=I%20want%20to%20buy%20this%20car"
              target="_blank"
            >
              WhatsApp
            </a>
            <AiOutlineWhatsApp size={25} color="#34B7F1" title="WhatsApp" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
