import React, { useEffect, useState } from "react";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineMessage,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getAllProductsShop } from "../../redux/actions/product";
import { server } from "../../server";
import styles from "../../styles/styles";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../redux/actions/wishlist";
import { addTocart } from "../../redux/actions/cart";
import { toast } from "react-toastify";
import Ratings from "./Ratings";
import axios from "axios";
import { BsWhatsapp } from "react-icons/bs";
import { IoIosCall } from "react-icons/io";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./carouselStyles.css"; // Import the custom styles
import { FaShareAlt } from "react-icons/fa";
const ProductDetails = ({ data }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const { products } = useSelector((state) => state.products);
  const [count, setCount] = useState(1);
  const [click, setClick] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllProductsShop(data && data?.shop._id));
    if (wishlist && wishlist.find((i) => i._id === data?._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [data, wishlist]);

  const incrementCount = () => {
    setCount(count + 1);
  };

  const decrementCount = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (data.stock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, qty: count };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };

  const totalReviewsLength =
    products &&
    products.reduce((acc, product) => acc + product.reviews.length, 0);

  const totalRatings =
    products &&
    products.reduce(
      (acc, product) =>
        acc + product.reviews.reduce((sum, review) => sum + review.rating, 0),
      0
    );

  const avg = totalRatings / totalReviewsLength || 0;

  const averageRating = avg.toFixed(2);

  const handleMessageSubmit = async () => {
    if (isAuthenticated) {
      const groupTitle = data._id + user._id;
      const userId = user._id;
      const sellerId = data.shopId;
      await axios
        .post(`${server}/conversation/create-new-conversation`, {
          groupTitle,
          userId,
          sellerId,
        })
        .then((res) => {
          navigate(`/inbox?${res.data.conversation._id}`);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    } else {
      toast.error("Please login to create a conversation");
    }
  };

  return (
    <div className="bg-white">
      {data ? (
        <div className={`${styles.section} w-[90%] 800px:w-[80%]`}>
          <div className="w-full py-5">
            <div className="block w-full 800px:flex">
              <div className="w-full 800px:w-[50%]">
                <Carousel showThumbs={true} selectedItem={0}>
                  {data.images.map((i, index) => (
                    <div key={index}>
                      <img src={i.url} alt="" className="w-[80%]" />
                    </div>
                  ))}
                </Carousel>
              </div>

              <div className="w-full 800px:w-[50%] pt-5 800px:p-6">
                <h1 className={`${styles.productTitle}`}>{data.name}</h1>
                <p>{data.description}</p>
                <div className="flex pt-3">
                  <h4 className={`${styles.productDiscountPrice}`}>
                    ₹ {data.discountPrice}
                  </h4>
                  <h3 className={`${styles.price}`}>
                    {data.originalPrice ? data.originalPrice + "₹" : null}
                  </h3>
                </div>

                <div className="flex items-center pt-8 gap-2">
                  <Link to={`/shop/preview/${data?.shop._id}`}>
                    <img
                      src={`${data?.shop?.avatar?.url}`}
                      alt=""
                      className="w-[50px] h-[50px] rounded-full mr-2"
                    />
                  </Link>
                  <div className="800px:pr-8">
                    <Link to={`/shop/preview/${data?.shop._id}`}>
                      <h3 className={`${styles.shop_name} pb-1 pt-1`}>
                        {data.shop.name}
                      </h3>
                    </Link>
                  </div>
                  <div
                    className={`${styles.buttonwhatsapp} !mt-6 !rounded !h-16 flex items-center`}
                  >
                    <a
                      href={`https://api.whatsapp.com/send?phone=9470814991&text=I%20want%20to%20buy%20this%20product.%20Here's%20the%20link:%20https://mahiautomobile.com/product/${data?._id}`}
                      target="_blank"
                    >
                      <span className="text-white flex items-center font-semibold p-1">
                        <BsWhatsapp className="flex mr-1" size={20} />
                        Whatsapp
                      </span>
                    </a>
                  </div>
                  <div
                    className={`${styles.buttoncalling} !mt-6 !rounded !h-16 flex items-center`}
                  >
                    <a href="tel:9470814991">
                      <IoIosCall size={30} color="#fff" />
                    </a>
                  </div>
                  <div
                    className={`${styles.buttonshare} !mt-6 !rounded !h-16 flex items-center`}
                  >
                    <FaShareAlt
                      size={25}
                      className="cursor-pointer"
                      onClick={() => {
                        const productLink = `https://mahiautomobile.com/product/${data?._id}`;

                        if (navigator.share) {
                          navigator
                            .share({
                              title: "Check out this product!",
                              url: productLink,
                            })
                            .then(() =>
                              console.log("Product link shared successfully!")
                            )
                            .catch((error) =>
                              console.error("Error sharing:", error)
                            );
                        } else {
                          alert(
                            "Sharing is not supported on this browser. Copy the link: " +
                              productLink
                          );
                        }
                      }}
                      color="#fff"
                      title="Share"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ProductDetailsInfo
            data={data}
            products={products}
            totalReviewsLength={totalReviewsLength}
            averageRating={averageRating}
          />
          <br />
          <br />
        </div>
      ) : null}
    </div>
  );
};

const ProductDetailsInfo = ({
  data,
  products,
  totalReviewsLength,
  averageRating,
}) => {
  const [active, setActive] = useState(1);

  useEffect(() => {
    setTimeout(() => {
      const divFullProductDetail = document.querySelector(
        ".full-product-detail"
      );
      let html = data.fullProductDetails;
      divFullProductDetail.insertAdjacentHTML("afterbegin", html);
    }, 100);
  }, []);
  return (
    <>
      <div className="bg-[#f5f6fb] px-3 800px:px-10 py-2 rounded">
        <div className="w-full flex justify-start border-b pt-10 pb-2">
          <div className="relative mr-10">
            <h5
              className="text-[#000] text-[18px] px-1 leading-5 font-[600] cursor-pointer 800px:text-[20px]"
              onClick={() => setActive(1)}
            >
              Product Details
            </h5>
            {active === 1 ? (
              <div className={`${styles.active_indicator}`} />
            ) : null}
          </div>
        </div>
        {active === 1 ? (
          <div className="py-2 pb-10">
            <div className="flex flex-col">
              <div className="flex justify-between border-b py-2">
                <span className="font-bold">Model:</span>
                <span>{data.model}</span>
              </div>
              <div className="flex justify-between border-b py-2">
                <span className="font-bold">Condition:</span>
                <span>{data.condition}</span>
              </div>
              <div className="flex justify-between border-b py-2">
                <span className="font-bold">Year:</span>
                <span>{data.year}</span>
              </div>
              <div className="flex justify-between border-b py-2">
                <span className="font-bold">KM:</span>
                <span>{data.km}</span>
              </div>
              <div className="flex justify-between border-b py-2">
                <span className="font-bold">Fuel Type:</span>
                <span>{data.fuelType}</span>
              </div>
              <div className="flex justify-between border-b py-2">
                <span className="font-bold">Transmission:</span>
                <span>{data.transmission}</span>
              </div>
              <div className="flex justify-between border-b py-2">
                <span className="font-bold">No. of Owners:</span>
                <span>{data.owners}</span>
              </div>
              <div className="flex justify-between py-2">
                <span className="font-bold">RTO:</span>
                <span>{data.rto}</span>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ProductDetails;
