import React, { useEffect, useRef, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";
import { createProduct } from "../../redux/actions/add-new-product";
import { categoriesData } from "../../static/data";
import { toast } from "react-toastify";
import ChipsArray from "../../helper/chip";
// import { Editor } from '@tinymce/tinymce-react';
import TinyEditor from "../../helper/tiny";
// import { Editor } from '@tinymce/tinymce-react';

const CreateProduct = () => {
  const { seller } = useSelector((state) => state.seller);
  const { success, error } = useSelector((state) => state.newProducts);
  const tagInputRef = useRef(null);
  // const editorRef = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [images, setImages] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState([]);
  // const [tags, setTags] = useState('');
  const [model, setModel] = useState("");
  const [condition, setCondition] = useState("");
  const [year, setYear] = useState("");
  const [fuelType, setFuelType] = useState("");
  const [km, setKm] = useState("");
  const [transmission, setTransmission] = useState("");
  const [owners, setOwners] = useState("");
  const [rto, setRto] = useState("");
  const [originalPrice, setOriginalPrice] = useState();
  const [discountPrice, setDiscountPrice] = useState();
  const [stock, setStock] = useState();
  const [fullProductDetails, setFullProductDetails] = useState();
  // ====================LOADER ============================
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);

  //   // Simulate an API call or any time-consuming operation
  //   setTimeout(() => {
  //     // After the operation is complete, set loading back to false
  //     setLoading(false);
  //   }, 2000); // You can adjust the time as needed
  // };
  // =================================

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success("Added New Product Successfully!");
      navigate("/dashboard");
      window.location.reload();
    }
  }, [dispatch, error, success]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    setImages([]);

    files.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImages((old) => [...old, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // =======================
    setLoading(true);

    // Simulate an API call or any time-consuming operation
    const simulateProgress = () => {
      const interval = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress < 100) {
            return prevProgress + 10;
          } else {
            clearInterval(interval);
            setLoading(false);
            return 0;
          }
        });
      }, 500);
    };
    simulateProgress();
    // ==================
    const newForm = new FormData();

    images.forEach((image) => {
      newForm.set("images", image);
    });
    newForm.append("name", name);
    newForm.append("description", description);
    newForm.append("category", category);
    newForm.append("model", model);
    newForm.append("condition", condition);
    newForm.append("year", year);
    newForm.append("fuelType", fuelType);
    newForm.append("km", km);
    newForm.append("transmission", transmission);
    newForm.append("owners", owners);
    newForm.append("rto", rto);
    newForm.append("tags", tags);
    newForm.append("originalPrice", originalPrice);
    newForm.append("discountPrice", discountPrice);
    newForm.append("stock", stock);
    newForm.append("shopId", seller._id);
    newForm.append("fullProductDetails", fullProductDetails);
    dispatch(
      createProduct({
        name,
        description,
        category,
        model,
        condition,
        year,
        fuelType,
        km,
        transmission,
        owners,
        rto,
        tags,
        originalPrice,
        discountPrice,
        stock,
        shopId: seller._id,
        images,
        fullProductDetails,
      })
    );
  };
  console.log(createProduct, "createproduct");
  useEffect(() => {
    if (loading) {
      setProgress(0); // Reset progress when loading starts
    }
  }, [loading]);
  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(images);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setImages(items);
  };
  const handleTagInputKeyPress = (e) => {
    if (e.key === "Enter" && e.target.value.trim() !== "") {
      setTags((prevTags) => [
        ...prevTags,
        { key: prevTags.length, label: e.target.value },
      ]);
      setTimeout(() => {
        e.target.value = "";
      }, 0);

      e.preventDefault();

      if (tagInputRef.current) {
        tagInputRef.current.focus();
      }
    }
  };

  return (
    <div className="w-[90%] 800px:w-[90%] bg-white  shadow h-[80vh] rounded-[4px] p-3 overflow-y-scroll mt-10">
      <h5 className="text-[30px] font-Poppins text-center">Add New Product</h5>
      {/* create product form */}
      {loading ? (
        <div className="loader-container">
          <div className="loader-bar">
            <div className="progress" style={{ width: `${progress}%` }}>
              {progress}%
            </div>
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <br />
          <div>
            <label className="pb-2">
              Name <span className="text-red-500">*</span>
            </label>
            <input
              required
              type="text"
              name="name"
              value={name}
              className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your product name..."
            />
          </div>
          {/* <div>
            <label className="pb-2">
              Short Description <span className="text-red-500">*</span>
            </label>
            <textarea
              cols="30"
              required
              rows="5"
              type="text"
              name="description"
              value={description}
              className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter your product description..."
            ></textarea>
          </div> */}
          {/* <div>
            <label className="pb-2">
              Full Product Details
              <span className="text-red-500">*</span>
            </label>
            <div className="mt-2">
              <TinyEditor
                fullProductDetails={fullProductDetails}
                setFullProductDetails={setFullProductDetails}
              />
            </div>
          </div> */}

          <br />
          <div>
            <label className="pb-2">
              Category <span className="text-red-500">*</span>
            </label>
            <select
              required
              className="w-full mt-2 border h-[35px] rounded-[5px]"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="Choose a category">Choose a category</option>
              {categoriesData &&
                categoriesData.map((i) => (
                  <option value={i.title} key={i.title}>
                    {i.title}
                  </option>
                ))}
            </select>
          </div>
          <br />
          <div>
            <label className="pb-2">
              Tags <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="tags"
              ref={tagInputRef}
              // value={tags}
              className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              // onChange={(e) => setTags(e.target.value)}
              onKeyDown={handleTagInputKeyPress}
              placeholder="Enter your product tags..."
            />
          </div>
          <br />
          <div>
            {/* {ChipsArray()} */}
            <ChipsArray tags={tags} setTags={setTags} />
          </div>
          <div>
            <label className="pb-2">
              Model <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="model"
              value={model}
              className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setModel(e.target.value)}
              placeholder="Enter the model..."
            />
          </div>
          <div>
            <label className="pb-2">
              Condition <span className="text-red-500">*</span>
            </label>
            <select
              name="condition"
              value={condition}
              className="mt-2 block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] bg-white text-gray-800 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setCondition(e.target.value)}
            >
              <option value="" disabled>
                Select condition
              </option>
              <option value="Like New">Like New</option>
              <option value="Well Maintained">Well Maintained</option>
              <option value="Good">Good</option>
              <option value="Fair">Normal</option>
            </select>
          </div>

          <div>
            <label className="pb-2">
              Year <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="year"
              value={year}
              className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setYear(e.target.value)}
              placeholder="Enter the year..."
            />
          </div>
          <div>
            <label className="pb-2">
              Fuel Type <span className="text-red-500">*</span>
            </label>
            <select
              name="fuelType"
              value={fuelType}
              className="mt-2 block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] bg-white text-gray-800 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setFuelType(e.target.value)}
            >
              <option value="" disabled>
                Select fuel type
              </option>
              <option value="Petrol">Petrol</option>
              <option value="Diesel">Diesel</option>
              <option value="Hybrid">Hybrid</option>
              <option value="Petrol + CNG">Petrol + CNG</option>
              <option value="Electric">Electric</option>
            </select>
          </div>

          <div>
            <label className="pb-2">
              KM <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="km"
              value={km}
              className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setKm(e.target.value)}
              placeholder="Enter the KM..."
            />
          </div>
          <div>
            <label className="pb-2">
              Transmission <span className="text-red-500">*</span>
            </label>
            <select
              name="transmission"
              value={transmission}
              className="mt-2 block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setTransmission(e.target.value)}
              required
            >
              <option value="">Select transmission type</option>
              <option value="AUTOMATIC">Automatic</option>
              <option value="MANUAL">Manual</option>
            </select>
          </div>

          <div>
            <label className="pb-2">
              No. of Owners <span className="text-red-500">*</span>
            </label>
            <select
              name="owners"
              value={owners}
              className="mt-2 block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setOwners(e.target.value)}
              required
            >
              <option value="">Select number of owners</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
          </div>

          <div>
            <label className="pb-2">
              RTO <span className="text-red-500">*</span>
            </label>
            <select
              name="rto"
              value={rto}
              className="mt-2 block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setRto(e.target.value)}
              required
            >
              <option value="">Select RTO</option>
              <option value="PATNA">PATNA</option>
              <option value="GAYA">GAYA</option>
              <option value="BHOJPUR">BHOJPUR</option>
              <option value="CHAPARA">CHAPARA</option>
              <option value="MOTIHARI">MOTIHARI</option>
              <option value="MUZAFFARPUR">MUZAFFARPUR</option>
              <option value="DARBHANGA">DARBHANGA</option>
              <option value="MUNGER">MUNGER</option>
              <option value="BEGUSARAI">BEGUSARAI</option>
              <option value="BHAGALPUR">BHAGALPUR</option>
              <option value="PURNEA">PURNEA</option>
              <option value="SAHARSA">SAHARSA</option>
              <option value="NALANDA">NALANDA</option>
              <option value="BETTIAH">BETTIAH</option>
              <option value="ROHTAS">ROHTAS</option>
              <option value="JEHANABAD">JEHANABAD</option>
              <option value="AURANGABAD">AURANGABAD</option>
              <option value="NAWADA">NAWADA</option>
              <option value="GOPALGANJ">GOPALGANJ</option>
              <option value="SIWAN">SIWAN</option>
              <option value="SITAMARHI">SITAMARHI</option>
              <option value="VAISHALI">VAISHALI</option>
              <option value="MADHUBANI">MADHUBANI</option>
              <option value="SAMASTIPUR">SAMASTIPUR</option>
              <option value="KHAGARIA">KHAGARIA</option>
              <option value="KISHANGANJ">KISHANGANJ</option>
              <option value="ARARIA">ARARIA</option>
              <option value="KATIHAR">KATIHAR</option>
              <option value="MADHEPURA">MADHEPURA</option>
              <option value="BUXUR">BUXUR</option>
              <option value="BHABHUA">BHABHUA</option>
              <option value="JAMUI">JAMUI</option>
              <option value="SUPAUL">SUPAUL</option>
              <option value="BANKA">BANKA</option>
              <option value="SHEIKHPURA">SHEIKHPURA</option>
              <option value="LAKHISARAI">LAKHISARAI</option>
              <option value="SHEOHAR">SHEOHAR</option>
              <option value="ARAWAL">ARAWAL</option>
            </select>
          </div>

          <br />
          <div>
            <label className="pb-2">Original Price</label>
            <input
              type="number"
              name="price"
              value={originalPrice}
              className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setOriginalPrice(e.target.value)}
              placeholder="Enter your product price..."
            />
          </div>
          <br />
          <div>
            <label className="pb-2">
              Price (With Discount) <span className="text-red-500">*</span>
            </label>
            <input
              required
              type="number"
              name="price"
              value={discountPrice}
              className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setDiscountPrice(e.target.value)}
              placeholder="Enter your product price with discount..."
            />
          </div>
          {/* <div>
            <label className="pb-2">
              Product Stock <span className="text-red-500">*</span>
            </label>
            <input
              required
              type="number"
              name="price"
              value={stock}
              className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              onChange={(e) => setStock(e.target.value)}
              placeholder="Enter your product stock..."
            />
          </div> */}
          <div>
            <label className="pb-2">
              Upload Images <span className="text-red-500">*</span>
            </label>
            <input
              type="file"
              name=""
              id="upload"
              className="hidden"
              multiple
              onChange={handleImageChange}
            />
            <div className="w-full flex items-center flex-wrap">
              <label htmlFor="upload">
                <AiOutlinePlusCircle size={30} className="mt-3" color="#555" />
              </label>
              {images &&
                images.map((i) => (
                  <img
                    src={i}
                    key={i}
                    alt=""
                    className="h-[120px] w-[120px] object-cover m-2"
                  />
                ))}
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="imagesDroppable" direction="horizontal">
                {(provided) => (
                  <div
                    className="w-full flex items-center flex-wrap"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {images.map((image, index) => (
                      <Draggable key={image} draggableId={image} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <img
                              src={image}
                              alt=""
                              className="h-[120px] w-[120px] object-cover m-2"
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <br />
            <div>
              <input
                type="submit"
                value="Create"
                className="mt-2 cursor-pointer appearance-none text-center block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default CreateProduct;
