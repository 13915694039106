import React, { useState } from "react";
import {
  AiFillHeart,
  AiFillStar,
  AiOutlineEye,
  AiOutlineHeart,
  AiOutlineShoppingCart,
  AiOutlineStar,
  AiOutlineWhatsApp,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import styles from "../../../styles/styles";
import { useDispatch, useSelector } from "react-redux";
import ProductDetailsCard from "../ProductDetailsCard/ProductDetailsCard";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../redux/actions/wishlist";
import { useEffect } from "react";
import { addTocart } from "../../../redux/actions/cart";
import { toast } from "react-toastify";
import Ratings from "../../Products/Ratings";
import { BsWhatsapp } from "react-icons/bs";
import { IoIosCall } from "react-icons/io";
import {
  FaGasPump,
  FaTransmission,
  FaTachometerAlt,
  FaCog,
  FaShareAlt,
  FaStopwatch,
} from "react-icons/fa";
const ProductCard = ({ data, isEvent }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const [click, setClick] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (wishlist && wishlist.find((i) => i._id === data._id)) {
      setClick(true);
    } else {
      setClick(false);
    }
  }, [wishlist]);

  const removeFromWishlistHandler = (data) => {
    setClick(!click);
    dispatch(removeFromWishlist(data));
  };

  const addToWishlistHandler = (data) => {
    setClick(!click);
    dispatch(addToWishlist(data));
  };

  const handleClick = () => {
    // Redirect to the product detail page
    window.location.href = `/product/${data._id}`; // Change this path based on your routing setup
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart && cart.find((i) => i._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      if (data.stock < 1) {
        toast.error("Product stock limited!");
      } else {
        const cartData = { ...data, qty: 1 };
        dispatch(addTocart(cartData));
        toast.success("Item added to cart successfully!");
      }
    }
  };

  return (
    <>
      <div
        className="w-full h-[400px] bg-white rounded-lg shadow-sm p-4 relative cursor-pointer"
        onClick={handleClick}
      >
        {/* <div className="flex justify-end"></div> */}
        <div
          className=" relative flex top-[-1px] left-[-4px]"
          style={{ alignItems: "flex-start" }}
        >
          {data.originalPrice && (
            <span className="text-[11px] md:text-xs w-[70px] h-[25px] font-bold text-white text-brand-light uppercase inline-block bg-[#dd1717] px-2.5 pt-1 pb-[3px] mx-0.5 sm:mx-1 rounded-tl-md rounded-br-md">
              {Math.round(
                ((data.originalPrice - data.discountPrice) /
                  data.originalPrice) *
                  100
              )}
              % OFF
            </span>
          )}
        </div>

        <Link
          to={`${
            isEvent === true
              ? `/product/${data._id}?isEvent=true`
              : `/product/${data._id}`
          }`}
        >
          <div className="w-[90%]">
            <img
              src={`${data.images && data.images[0]?.url}`}
              alt=""
              className="w-full h-[170px] object-contain"
            />
          </div>
        </Link>
        <Link to={`/shop/preview/${data?.shop._id}`}>
          <h5 className={`${styles.shop_name}`}>{data.shop.name}</h5>
        </Link>
        <Link
          to={`${
            isEvent === true
              ? `/product/${data._id}?isEvent=true`
              : `/product/${data._id}`
          }`}
        >
          <h4 className="pb-3 font-[500]">
            {data.name.length > 40 ? data.name.slice(0, 40) + "..." : data.name}
          </h4>
          {/* New Information */}
          <div className="flex justify-around gap-2 text-sm pb-2">
            <div className="flex items-center bg-white shadow-md rounded-lg p-1 transition-transform transform hover:scale-105">
              <FaGasPump className=" text-green-500" size={10} />
              <span className="text-gray-800 text-sm font-bold">
                {data.fuelType || ""}
              </span>
            </div>
            <div className="flex items-center bg-white shadow-md rounded-lg p-1 transition-transform transform hover:scale-105">
              <FaCog className=" text-blue-500" size={10} />
              <span className="text-gray-800 font-bold">
                {data.transmission || ""}
              </span>
            </div>
            <div className="flex items-center bg-white shadow-md rounded-lg p-1 transition-transform transform hover:scale-105">
              <FaStopwatch className=" text-orange-500" size={10} />
              <span className="text-gray-800 font-bold">{data.year || ""}</span>
            </div>
          </div>
          <div className="flex">
            <Ratings rating={data?.ratings} />
          </div>
          <div className="py-2 flex items-center justify-between">
            <div className="flex">
              <h5 className={`${styles.productDiscountPrice}`}>
                ₹{" "}
                {data.originalPrice === 0
                  ? data.originalPrice
                  : data.discountPrice}
              </h5>
              <h4 className={`${styles.price}`}>
                {data.originalPrice ? data.originalPrice + " ₹" : null}
              </h4>
            </div>
            {/* <span className="font-[400] text-[17px] text-[#68d284]">
              {data?.sold_out} sold
            </span> */}
          </div>
        </Link>

        {/* side options */}
        <div>
          {click ? (
            <AiFillHeart
              size={22}
              className="cursor-pointer absolute right-2 top-5"
              onClick={() => removeFromWishlistHandler(data)}
              color={click ? "red" : "#333"}
              title="Remove from wishlist"
            />
          ) : (
            <AiOutlineHeart
              size={22}
              className="cursor-pointer absolute right-2 top-5"
              onClick={() => addToWishlistHandler(data)}
              color={click ? "red" : "#333"}
              title="Add to wishlist"
            />
          )}
          <AiOutlineEye
            size={22}
            className="cursor-pointer absolute right-2 top-14"
            onClick={() => setOpen(!open)}
            color="#dd1717"
            title="Quick view"
          />
          <BsWhatsapp
            size={25}
            className="cursor-pointer absolute right-2 top-24"
            onClick={() => {
              const whatsappLink = `https://api.whatsapp.com/send?phone=9470814991&text=I%20want%20to%20buy%20this%20product.%20Here's%20the%20link:%20https://mahiautomobile.com/product/${data?._id}`;
              window.open(whatsappLink, "_blank");
            }}
            color="#02B290"
            title="Contact Whatsapp"
          />

          <div>
            <a href="tel:9470814991">
              <IoIosCall
                className="cursor-pointer absolute right-2 top-36"
                size={30}
                color="#5055ff"
              />
            </a>
          </div>
          <FaShareAlt
            size={25}
            className="cursor-pointer absolute right-2 top-52"
            onClick={() => {
              const productLink = `https://mahiautomobile.com/product/${data?._id}`;

              if (navigator.share) {
                navigator
                  .share({
                    title: "Check out this product!",
                    url: productLink,
                  })
                  .then(() => console.log("Product link shared successfully!"))
                  .catch((error) => console.error("Error sharing:", error));
              } else {
                alert(
                  "Sharing is not supported on this browser. Copy the link: " +
                    productLink
                );
              }
            }}
            color="#555"
            title="Share"
          />
          {open ? <ProductDetailsCard setOpen={setOpen} data={data} /> : null}
        </div>
      </div>
    </>
  );
};

export default ProductCard;
