import React, { useState, useEffect } from "react";
import { FaCarSide, FaMoneyCheckAlt, FaSmile } from "react-icons/fa";

const WelcomeBanner = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const isBannerShown = sessionStorage.getItem("welcomeBannerShown");

    if (!isBannerShown) {
      setVisible(true);
      sessionStorage.setItem("welcomeBannerShown", "true"); // Set flag in sessionStorage
    }

    const timer = setTimeout(() => {
      setVisible(false);
    }, 10000); // 10 seconds

    return () => clearTimeout(timer);
  }, []);

  if (!visible) {
    return null;
  }

  return (
    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[90%] max-w-lg p-8 bg-gradient-to-r from-[#1a73e8] to-[#673ab7] text-white rounded-2xl shadow-xl z-50 flex flex-col items-center justify-center animate-slide-in backdrop-blur-md">
      <div className="relative">
        <div className="absolute -inset-1 bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 rounded-full blur-lg"></div>
        <h1 className="relative text-3xl font-extrabold mb-4 text-center drop-shadow-lg">
          Mahi Automobile में आपका स्वागत है!
        </h1>
      </div>
      <p className="text-lg text-center max-w-[80%] leading-relaxed drop-shadow-md">
        Mahi Automobile लेकर आया है आपके लिए सेकंड हैंड कार का बहुत सारा विकल्प,
        जिससे अब आपकी कार खरीदने का सपना सच होगा क्योंकि हम लोन की सुविधा भी
        देते हैं।
      </p>
      <button className="mt-6 px-6 py-2 bg-white text-blue-600 font-semibold rounded-full shadow-md hover:bg-blue-50 hover:shadow-lg transition-all duration-300">
        Explore Now
      </button>
    </div>
  );
};

export default WelcomeBanner;
